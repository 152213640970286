<template>
  <gf-card>
    <gf-content v-loading="loading" overflow>
      <template #title>
        <div class="title">
          <span class="font-weight-bold mr-2">{{ title }}</span>
          <gf-label v-if="sm.is_fulfilled" inline pill type="info">Fulfilled</gf-label>
        </div>
      </template>
      <template #toolbar>
        <el-button-group v-if="!sm.is_fulfilled" style="margin-left: 10px">
          <el-button size="small" @click="edit">Edit</el-button>
          <el-button size="small" @click="remove">Delete</el-button>
        </el-button-group>
        <el-button size="small" style="margin-left: 10px" @click="print">Print</el-button>
        <el-button v-if="!sm.is_fulfilled" size="small" style="margin-left: 10px"
          @click="createFulfillment">Ship</el-button>
        <el-button v-if="sm.is_fulfilled" size="small" style="margin-left: 10px" @click="deleteFulfillment">Delete
          Fulfillment</el-button>
      </template>
      <template #header>
        <el-row>
          <el-col :lg="9" :md="12" :sm="24" :xl="9">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Bill To</span>
                <span class="font-size-sm font-weight-normal pr-3">Ship To</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ sm.bill_to.label }}</span>
                <span class="font-size-sm pl-3">{{ sm.ship_to.label }}</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="9" :md="12" :sm="24" :xl="9">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Packed</span>
                <span class="font-size-sm font-weight-normal pr-3">Fulfilled</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ $DateFormat(sm.packed) }}</span>
                <span v-if="!sm.is_fulfilled" class="font-size-sm pl-3">NA</span>
                <span v-else class="font-size-sm pl-3">{{ $DateFormat(shipDate) }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <div class="table">
        <gf-table :data="sm.items" :scrollable="false">
          <el-table-column label="Item Name" min-width="200">
            <template slot-scope="slot">
              <div class="gf-item">
                <div class="gf-item__name">
                  <span v-if="slot.row.product.name === slot.row.variant.name && slot.row.variant.is_pack === 0">{{
                    slot.row.variant.name }}</span>
                  <span v-else-if="slot.row.variant.is_pack === 0">{{ slot.row.product.name }} - {{ slot.row.variant.name
                  }}</span>
                  <span v-else>
                    <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name
                    }} - {{ slot.row.variant.name }}</span>
                    <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{
                      slot.row.variant.name }}</span>
                    <span v-if="slot.row.variant.is_pack === 1"> (Pack of {{ slot.row.variant.pack_size }})</span>
                  </span>
                </div>
                <div class="gf-item__sub">
                  <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Qty Packed" min-width="120">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.quantity) }}
            </template>
          </el-table-column>
          <el-table-column label="Not Packed" min-width="120">
            <template slot-scope="slot">
              {{ $NumberFormat(parseFloat(slot.row.total_quantity) - parseFloat(slot.row.order_item.packed)) }}
            </template>
          </el-table-column>
          <el-table-column label="Total Qty" min-width="120">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.total_quantity) }}
            </template>
          </el-table-column>
          <el-table-column label="Total" min-width="100" prop="total">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount
                / 100))) }}
            </template>
          </el-table-column>
        </gf-table>
      </div>
      <div class="footer mt-2">
        <div class="note">
          <span>{{ sm.message }}</span>
        </div>
        <div class="gf-summary">
          <ul class="summary">
            <li class="summary__item">
              <span class="text">Total Units</span>
              <span class="value">{{ $NumberFormat(sm.units) }}</span>
            </li>
            <li v-if="sm.is_inclusive !== 2" class="summary__item">
              <span class="text">Subtotal</span>
              <span class="value">{{ $NumberFormat(sm.subtotal) }}</span>
            </li>
            <div v-if="sm.is_inclusive !== 2">
              <li v-for="(vat, index) in sm.vats" :key="index" class="summary__item">
                <span class="text">{{ (sm.is_inclusive) ? 'Including' : 'Plus' }} VAT ({{ vat.rate }}%)</span>
                <span class="value">{{ $NumberFormat(vat.value) }}</span>
              </li>
            </div>
            <li class="summary__item">
              <span class="text">Total</span>
              <span class="value">{{ $NumberFormat(sm.total) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </gf-content>
  </gf-card>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  justify-content: center;

  .title,
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    align-items: flex-end;

    span {
      border-right: 1px solid #ebedf3;
    }
  }

  .content {
    align-items: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesShipmentService from '@/services/v1/SalesShipment'

export default {
  props: {
    title: String,
    updateChild: Boolean
  },
  data() {
    return {
      loading: false,
      so: {
        so_number: null
      },
      sm: {
        packed: null,
        is_inclusive: 0,
        message: null,
        units: null,
        subtotal: null,
        vats: [],
        total: null,
        is_fulfilled: null,
        items: [],
        bill_to: {
          label: null
        },
        ship_to: {
          label: null
        },
        ship_from: {
          name: null
        }
      },
      shipDate: null,
      // services
      soService: null,
      pmService: null
    }
  },
  watch: {
    updateChild: function () {
      if (this.updateChild) {
        this.getShipment()
        this.$emit('update:updateChild', false)
      }
    },
    $route(to, from) {
      if (to !== from) {
        this.loadPage()
      }
    }
  },
  methods: {
    // handler
    edit() {
      this.$router.push({ name: 'shipment-edit', params: { id: this.$route.params.id, sid: this.$route.params.sid } })
    },
    print() {
      this.$router.push({ name: 'shipment-print-pack', params: { id: this.$route.params.id, sid: this.$route.params.sid } })
    },
    remove() {
      this.$confirm('You are attempting to delete this Shipment. This action cannot be undone.', 'Are you sure?', {
        confirmButtonText: 'Yes, Delete Shipment',
        cancelButtonText: 'Cancel'
      }).then(async () => {
        try {
          this.loading = true
          const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
          await ssService.del()
          this.$message.success(`${this.title} successfully deleted`)
          this.$emit('update:update', true)
          this.$router.push({ name: 'shipment-list', params: { id: this.$route.params.id } })
        } catch (error) {
          this.$Error(error)
        } finally {
          this.loading = false
        }
      }).catch(() => { })
    },
    deleteFulfillment() {
      this.$confirm('You are attempting to delete the fulfillment of this shipment. This action cannot be undone.', 'Are you sure?', {
        confirmButtonText: 'Yes, Delete Fulfillment',
        cancelButtonText: 'Cancel'
      }).then(async () => {
        try {
          this.loading = true
          const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
          await ssService.deleteFullfillment()
          this.$message.success('Fulfillment was successfully deleted')

          this.getShipment()
          this.$emit('update:update', true)
        } catch (error) {
          this.$Error(error)
        } finally {
          this.loading = false
        }
      }).catch(() => { })
    },
    async createFulfillment() {
      try {
        this.loading = true
        const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
        await ssService.createFulfillment()
        this.$message.success('Fulfillment was successfully created')
        this.getShipment()
        this.$emit('update:update', true)
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },

    // Service Fetch
    async getSalesOrder() {
      try {
        this.loading = true
        const soService = new SalesOrderService(this.$route.params.id)
        const result = await soService.getSOandStatus()
        this.so = result.data
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },
    async getShipment() {
      try {
        this.loading = true
        const ssService = new SalesShipmentService(this.$route.params.id, this.$route.params.sid)
        const result = await ssService.get()
        this.sm = result.data

        if (this.sm.is_fulfilled) {
          const ssService = new SalesShipmentService(this.$route.params.id)
          const result2 = await ssService.getFulfillmentStatus()
          this.shipDate = result2.data.rows.find(s => s.shipment_id === parseInt(this.$route.params.sid)).created_at
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    },

    // helper
    async loadPage() {
      await this.getSalesOrder()
      await this.getShipment()

      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so.so_number },
        { title: 'Shipment' },
        { title: this.title }
      ])
    }
  },
  async mounted() {
    this.loadPage()
  }
}
</script>
